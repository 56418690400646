import React from 'react';
import Login from "./containers/Login/Login";
import classes from './App.module.css';

function App() {
    return (
        <div className={classes.YotascaleMain}>
            <Login> </Login>
        </div>
    );
}

export default App;
