import axios from 'axios';
import * as Configs from '../../config';

import * as actionTypes from './actionTypes';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authReset = () => {
    return {
        type: actionTypes.AUTH_RESET
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const authSuccess = (successMsg, token, resetEmail) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        successMsg: successMsg,
        token: token,
        resetEmail: resetEmail
    }
};

export const auth = (email, password, redirectUrl, token) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            username: email,
            password: password,
            token: token
        };
        axios.post('login', authData, {withCredentials: true})
            .then(response => {
                if(redirectUrl) {
                    window.location.href = Configs.WebURL + redirectUrl;
                } else{
                    window.location.href = Configs.WebURL;
                }
            })
            .catch(err => {
                let msg = 'Something went wrong, please try again later.';
                if (err.response) {
                    switch (err.response.status) {
                        case 401:
                            msg = err.response.data.message;
                            break;
                        case 500:
                            msg = err.response.data;
                            break;
                        default:
                    }
                }
                dispatch(authFail(msg));
            });
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const forgotPassword = (email) => {
    return dispatch => {
        dispatch(authStart());
        const forgotPasswordData = {
            email: email
        };
        axios.post('user/forgot_password', forgotPasswordData)
            .then(response => {
                dispatch(authSuccess("An email with password reset link has been sent to your email address. "))
            })
            .catch(err => {
                const msg = (err.response && err.response.data && err.response.data.message) || 'Something went wrong, please try again later.';
                dispatch(authFail(msg));
            });
    };
};

export const fetchResetPasswordDetails = (token) => {
    return dispatch => {
        dispatch(authReset());
        dispatch(authStart());
        axios.get('user/validate_reset_token/' + token)
            .then(response => {
                let errorMsg = 'Something went wrong, please try again later.';
                if (response.data) {
                    if (response.data.status === 'success') {
                        dispatch(authSuccess(null, token, response.data.email));
                    } else {
                        dispatch(authFail('Token is invalid, please try with new token'));
                    }
                } else {
                    dispatch(authFail(errorMsg));
                }
            })
            .catch(err => {
                const msg = (err.response && err.response.data && err.response.data.message) || 'Something went wrong, please try again later.';
                dispatch(authFail(msg));
            });
    };
};

export const resetPassword = (password, confirmPassword, token) => {
    return dispatch => {
        dispatch(authStart());
        if (password !== confirmPassword) {
            dispatch(authFail('Password and confirm password does not match.'));
            return;
        }
        const resetPassword = {
            token: token,
            new_password: password,
            confirm_password: confirmPassword
        };
        axios.post('user/reset_forgotten_password', resetPassword)
            .then(response => {
                if(response.data && response.data.status === "success") {
                    dispatch(authSuccess("Password has been changed successfully."))
                } else {
                    const msg = (response.data && response.data.message) || 'Something went wrong, please try again later.'
                    dispatch(authFail(msg))
                }
            })
            .catch(err => {
                const msg = (err.response && err.response.data && err.response.data.message) || (err.response && err.response.data) || 'Something went wrong, please try again later.';
                dispatch(authFail(msg));
            });
    };
};

export const loginWithOrganization = (orgName, redirectUrl) => {
    return dispatch => {
        dispatch(authStart());
        let urlPath = 'auth/' + orgName;
        if (redirectUrl) {
            urlPath += '?redirectionUrl=' + redirectUrl;
        }
        axios.get(urlPath)
            .then(response => {
                let msg = 'Something went wrong, please try again later.';
                if (response.data) {
                    if (response.data.status === "error") {
                        msg = response.data.message;
                        dispatch(authFail(msg));
                    } else if (response.data.status === "success" && response.data.redirect_url !== '') {
                        window.location = response.data.redirect_url;
                    } else {
                        dispatch(authFail(msg));
                    }
                } else {
                    dispatch(authFail(msg));
                }
            })
            .catch(err => {
                const msg = (err.response && err.response.data && err.response.data.message) || 'Something went wrong, please try again later.';
                dispatch(authFail(msg));
            });
    };
};