import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {createStore, applyMiddleware, combineReducers, compose} from "redux";
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Provider} from 'react-redux';
import thunk from "redux-thunk";
import {BrowserRouter} from "react-router-dom";
import axios from "axios";
import * as Configs from './config';

import authenticationReducer from './store/reducer/authentication';


axios.defaults.baseURL = Configs.BaseURL;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.common['Authorization'] ='AUTH_TOKEN';
// axios.defaults.headers.post['Content-Type'] = 'application/json';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    auth: authenticationReducer
});

const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(thunk)
));
const app = (
    <Provider store={store}>
        <BrowserRouter basename={Configs.WebURLPrefix}>
            <App />
        </BrowserRouter>
    </Provider>
)

ReactDOM.render(app, document.getElementById('yotascale'));

serviceWorker.unregister();
