import React from "react";
import YotascaleLogo from '../../../assets/images/yotascale-logo-light-medium.png';
import YotascaleTagline from '../../../assets/images/yotascale-logo-tagline.png';
import classes from './Logo.module.css';
import Aux from "../../../hoc/Aux/Aux";

const Logo = () => {
    return (
        <Aux>
        <h1 className={classes.Logo}>
          <img src={YotascaleLogo} alt="Yotascale" className={classes.YotascaleLogo}/>
        </h1>
        <img className={classes.YotascaleLogoTagline} src={YotascaleTagline} alt="Cost Management for AWS" />
        </Aux>
    )
};

export default Logo;