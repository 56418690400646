import React, { useState, useEffect, useCallback } from "react";
import classes from './UserPassword.module.css';
import { Alert } from 'react-bootstrap'
import GoogleLogo from '../../../assets/images/brand_logos/google_logo.png'
import OrganizationIcon from '../../../assets/images/organization-icon.svg';
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions/index'
import Input from '../../UI/Input/Input';
import Button from "../../UI/Button/Button";
import * as Util from '../../../Utility/InputUtil';
import * as Configs from '../../../config';
// import * as LoginUtil from '../../../Utility/loginUtil';
import ReCAPTCHA from "react-google-recaptcha";
// import { GoogleReCaptchaProvider, useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';

const UserPassword = props => {

    const loading = useSelector(state => {
        return state.auth.loading;
    });
    const error = useSelector(state => {
        return state.auth.error;
    });

    const [token, setToken] = useState(undefined);
    // const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    const dispatch = useDispatch();
    const onAuth = (email, password, redirectUrl, token) => dispatch(actions.auth(email, password, redirectUrl, token));
    const resetAuth = () => dispatch(actions.authReset());
    const errorHandler = useCallback((error) => dispatch(actions.authFail(error)), [dispatch]);
    let recaptchaRef;

    const [formIsValid, setFormIsValid] = useState(false);
    const [sessionTimeout, setSessionTimeout] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('');
    const [errorMsgContainer, setErrorMsgContainer] = useState('')
    const [authForm, setAuthForm] = useState({
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: 'Username'
            },
            value: '',
            validation: {
                required: true,
                isEmail: true
            },
            valid: false,
            touched: false
        },
        password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Password'
            },
            value: '',
            validation: {
                required: true,
                minLength: 6
            },
            valid: false,
            touched: false
        }
    });
    const params = {};
    useEffect(() => {
        document.title = "Login | Yotascale";
        const query = new URLSearchParams(props.location.search);
        for (let param of query.entries()) {
            params[param[0]] = param[1];
        }
        let showError = false;
        let errorMsg = null;
        let authFailed = false;
        const authInvalid = params.msg === 'OAUTH_PROVIDER_INVALID';
        setSessionTimeout(params.session_timeout === 'true');
        setRedirectUrl(params.redirectionUrl);
        if (params.msg === 'NOT_A_VALID_GOOGLE_USER') {
            showError = true;
            errorMsg = 'Please log in using a different Google account.  If you reached this message in error, contact your administrator.';
            if (params.email) {
                errorMsg = 'You are logged in to Google as ' + params.email + '. Please log in using a different account.  If you reached this message in error, contact your administrator.';
            }
        } else if (params.status === 'custom_error' && params.msg) {
            showError = true;
            errorMsg = params.msg;
        } else if (params.msg !== 'OAUTH_PROVIDER_INVALID') {
            if (params.msg === 'OAUTH_FAILED' || params.msg === 'OAUTH_USER_INVALID') {
                authFailed = true;
            }
        }


        if (showError) {
            errorHandler(errorMsg)
        } else if (authInvalid) {
            errorHandler("Authentication failed. Please try other auth mechanism or contact your administrator.");
        } else if (authFailed) {
            errorHandler("Authentication failed. Contact your administrator.");
        } else if (params.no_account_found) {
            errorHandler("Yotascale application is currently being on-boarded. Please contact your administrator.");
        }

    }, [errorHandler, params, props.location.search])

    const inputChangedHandler = (event, controlName) => {
        const updatedControls = {
            ...authForm,
            [controlName]: {
                ...authForm[controlName],
                value: event.target.value,
                valid: Util.checkValidity(event.target.value, authForm[controlName].validation),
                touched: true
            }
        };

        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
            if (!formIsValid) {
                break;
            }
        }
        setFormIsValid(formIsValid);
        setAuthForm(updatedControls)
    };

    const submitHandler = (event) => {
        setSessionTimeout(false);
        event.preventDefault();
        onAuth(authForm.email.value, authForm.password.value, redirectUrl, token);
    };

    function onChange(value) {
        setToken(value);
    }
    const setCaptchaRef = (ref) => {
        if (ref) {
            return recaptchaRef = ref;
        }
    };

    const resetCaptcha = () => {
        recaptchaRef && recaptchaRef.reset();
        setToken('')
    }

    const formElementsArray = [];
    for (let key in authForm) {
        formElementsArray.push({
            id: key,
            config: authForm[key]
        });
    }
    let form = formElementsArray.map(formElement => (
        <Input
            key={formElement.id}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            value={formElement.config.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            changed={(event) => inputChangedHandler(event, formElement.id)} />
    ));


    const ForgotPasswordHandler = () => {
        resetAuth();
        props.history.push('/forgot_password');
    };
    const OrganizationHandler = () => {
        resetAuth();
        let urlPath = '/external_provider';
        if (redirectUrl) {
            // console.log(redirectUrl, encodeURI(redirectUrl), encodeURIComponent(redirectUrl));
            urlPath += '?redirectionUrl=' + encodeURIComponent(redirectUrl);
        }
        props.history.push(urlPath);

    };

    let googleUrl = Configs.BaseURL + 'auth/google';
    if (redirectUrl) {
        googleUrl += redirectUrl;
    } else {
        googleUrl += '/';
    }
    useEffect(() => {
        if (error || sessionTimeout) {
            resetCaptcha();
        }
        setErrorMsgContainer('')
        if (error) {
            setErrorMsgContainer(
                <Alert key='errorMsg' variant='danger'>
                    {error}
                </Alert>
            );
        } else if (sessionTimeout) {
            setErrorMsgContainer(
                <Alert key='warningMsg' variant='warning'>
                    Session expired. Please log in again.
                </Alert>
            );
        }
    }, [error, sessionTimeout])


    return (
        <div className={classes.UserPassword}>
            <a href={googleUrl}>
                <Button variant="light" className={classes.SignInBtn} block disabled={loading}>
                    <img src={GoogleLogo} style={{ marginBottom: '1px', height: '20px' }} alt="" />
                    <span> Sign in Using Google</span>
                </Button>
            </a>
            <Button variant="light" className={classes.SignInBtn} onClick={OrganizationHandler} disabled={loading}>
                <img src={OrganizationIcon} style={{ marginBottom: '1px', height: '20px' }} alt="" />
                <span> Sign in Using Organization Name</span>
            </Button>
            <div className={classes.OrGroup}>
                <div className={classes.OrLine}>&nbsp;</div>
                <div className={classes.OrText}>or</div>
                <div className={classes.OrLine}>&nbsp;</div>
            </div>
            <form onSubmit={submitHandler}>
                {form}
                {!loading && <div className={classes.captcha} >
                    <ReCAPTCHA
                        sitekey="6Lcvt5MnAAAAAHdbhSDWXTo9FIxP7KgfKj4GQovr"
                        // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // test key
                        ref={(r) => setCaptchaRef(r)}
                        onChange={onChange}
                    />
                </div>}
                {errorMsgContainer}
                <Button type="submit" variant="primary" block disabled={loading || !formIsValid || !token}>Login</Button>
            </form>
            <Button variant="link" color="white" block onClick={ForgotPasswordHandler} disabled={loading}> Forgot
                Password </Button>

        </div>
    );
};


export default UserPassword;