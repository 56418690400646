const hostName = window.location.hostname;
let serverUrl = '';
let extension = '';
let webUrl = 'https://' + hostName;
window.segmentAnalyticsKey = "8CaT5rcgJsXXFhyox9hprtlaoITb79mf";

    //PROD
if (hostName === "app.yotascale.io") {
    serverUrl = 'https://app-api.yotascale.io';

    //STAGING
} else if (hostName === "web.staging.yotascale.io") {
    serverUrl = 'https://rest-api.staging.yotascale.io';

    //DR
} else if (hostName === "web.dr.yotascale.io") {
    serverUrl = 'https://rest-api.dr.yotascale.io';

    //TEST
} else if (hostName === "web.test.yotascale.io") {
    window.segmentAnalyticsKey = '';
    serverUrl = 'https://rest-api.test.yotascale.io';

    //DEMO-PROD
} else if (hostName === "web.demo-prod.yotascale.io") {
    serverUrl = 'https://rest-api.demo-prod.yotascale.io';

    //DEMO-STAGING
} else if (hostName === "web.demo-staging.yotascale.io") {
    serverUrl = 'https://rest-api.demo-staging.local.yotascale.io';

} else if (hostName === "localhost.yotascale.io" || hostName === "localhost") {
    console.log('local')
    window.segmentAnalyticsKey = '';
    extension = '/static';
    webUrl = 'http://' + hostName + '/static/index.html';
    serverUrl = 'http://localhost.yotascale.io:5000';
    // serverUrl = 'https://app-api.yotascale.io';
    // serverUrl = 'https://rest-api.staging.yotascale.io';
}

export const WebURL = webUrl;
export const BaseURL = serverUrl + "/";
export const WebURLPrefix = extension + "/login.html";